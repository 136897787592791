#sidebar {
  background: #8a8a8e;
  box-sizing: border-box;
  flex-grow: 0;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5em;
  width: 13em;

  #minimap {
    background: #000;
  }

  .turn {
    font-size: 0.8em;

    &:before {
      content: ' (turn ';
    }

    &:after {
      content: ')';
    }
  }
}
