#notification {
  background: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0.5rem;
  bottom: 0;
  padding: 0.5rem;
  position: fixed;
  right: 0;

  &:empty {
    display: none;
  }
}
