.window {
  background: #edc;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
  color: #123;
  margin: 0;
  outline: 0;
  overflow: auto;
  padding: 0;
  position: fixed;

  &.modal {
    z-index: 10;

    &::backdrop {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &.position-auto {
    align-self: center;
    justify-self: center;
    margin: auto;
  }

  &.size-auto {
    max-height: 60%;
    max-width: 80vw;
    min-width: 40em;
  }

  > header {
    background: #38e;
    color: #edc;
    display: flex;
    font-weight: bold;
    padding: 0.5em;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.6);

    h3 {
      flex-grow: 1;
      margin: 0;
    }

    > button {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 95%;
      border: 1px solid #444;
      border-radius: 3px;
      font-size: 0;
      height: 1.3rem;
      margin-right: 0.33rem;
      padding: 0;
      width: 1.3rem;

      &:last-child {
        margin-right: 0;
      }

      &.close {
        background-image: url('feather-icons/dist/icons/x.svg');
      }

      &.maximise {
        background-image: url('feather-icons/dist/icons/maximize.svg');
      }
    }
  }

  &.maximised {
    border: 0;
    border-radius: 0;
    height: 100%;
    left: 0 !important;
    max-height: none;
    max-width: none;
    top: 0 !important;
    width: 100%;

    > header > button.maximise {
      background-image: url('feather-icons/dist/icons/minimize.svg');
    }
  }

  p {
    overflow-y: auto;
    margin: 1em 0.5em;
    padding: 0;
    max-height: 20em;
  }

  :not(header) button {
    margin: 1em 0.5em;
  }
}
