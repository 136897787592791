#game {
  align-items: stretch;
  display: flex;
  left: 0;
  min-height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 2s;
  width: 100vw;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
