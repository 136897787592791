.selectionWindow {
  select {
    background: transparent;
    border: 0;
    display: block;
    margin: 1em;
    max-height: calc(60vh - 8.5em);
    outline: 0;
    overflow: auto;
    width: calc(100% - 2em);
  }
}
