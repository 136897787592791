#mainmenu {
  background: url('../img/main-menu-bg.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  opacity: 0;
  transition: opacity 2s;
  width: 100vw;

  &.active {
    opacity: 1;

    nav {
      opacity: 1;
    }
  }

  nav {
    bottom: 5em;
    max-width: 20em;
    opacity: 0;
    position: absolute;
    transition: opacity 1.5s 0.5s;

    button {
      appearance: none;
      background: none;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      outline: 0;
      padding: 0.5em;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
      width: 100%;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &:focus {
        background: rgba(255, 255, 255, 0.4);
        color: #000;
      }
    }
  }

  footer {
    bottom: 5px;
    left: 5px;
    position: absolute;

    a {
      color: #fff;
    }
  }
}

.customise-world .option {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0.5rem 0;
}
