@use 'action-button';

.action {
  margin: 0 1em 1em;
  text-align: right;

  button {
    @include action-button.main();
    @include action-button.background-gradient-silver-ring();

    &.chooseResearch {
      @include action-button.background-gradient-silver-ring(#2966a8);
    }

    &.cityBuild {
      @include action-button.background-gradient-tinted(#ffa109);
    }

    &.civilDisorder {
      @include action-button.background-gradient-silver-ring(#a82929);

      img {
        width: calc(8px * var(--scale));
      }
    }

    &.endTurn {
      @include action-button.background-gradient-silver-ring(#25b906);
      @include action-button.white-child-svg();
      position: relative;

      &::before {
        animation: glow 2s infinite;
        background: #fff;
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &.spaceship {
      @include action-button.white-child-svg();
    }
  }
}

#actions {
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: absolute;
  right: 0;

  .action {
    margin: 0 1rem 1rem;
  }
}

#other-actions {
  position: absolute;
  right: 4rem;
  display: flex;
  flex-direction: row;
  padding: 1em;

  .action {
    margin: 0 0 0 0.5em;

    button {
      height: 2rem;
      width: 2rem;
    }
  }
}

@keyframes glow {
  10% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  90% {
    opacity: 0;
  }
}
