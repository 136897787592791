@mixin main() {
  appearance: none;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  height: 3.1rem;
  image-rendering: pixelated;
  outline: none;
  padding: 0;
  width: 3.1rem;

  img {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
    width: calc(11px * var(--scale));
  }

  &.small {
    height: 2rem !important;
    width: 2rem !important;

    img {
      width: calc(8px * var(--scale));
    }
  }

  &.large {
    font-size: 2.75rem;
    height: 4rem !important;
    width: 4rem !important;

    img {
      width: calc(14px * var(--scale));
    }
  }
}

@mixin background-gradient(
  $primary,
  $ring-1,
  $ring-2,
  $ring-3,
  $ring-4,
  $ring-5,
  $ring-6,
  $highlight:
    adjust-color($primary, $lightness: 29%, $hue: 6deg, $saturation: -40%)
) {
  background:
          // outside ring highlight
    radial-gradient(
      circle at 25% 25%,
      rgba(255, 255, 255, 0.3) 0%,
      transparent 80%
    ),
    // outside ring
    radial-gradient(
        circle,
        transparent 60%,
        $ring-1 60%,
        $ring-2 61%,
        $ring-3 62%,
        $ring-4 65%,
        $ring-5 66%,
        $ring-6 70%
      ),
    // center highlight
    radial-gradient(circle at 50% 15%, $highlight 0%, transparent 50%),
    // inner circle
    radial-gradient(
        circle,
        $primary 0%,
        adjust-color($primary, $lightness: -14%, $hue: 355deg) 60%,
        transparent 61%
      );
}

@mixin background-gradient-tinted($primary: #150d40) {
  @include background-gradient(
    $primary,
    adjust-color($primary, $lightness: -19%, $hue: 6deg, $saturation: -35%),
    adjust-color($primary, $lightness: -40%, $hue: 3deg, $saturation: -40%),
    adjust-color($primary, $lightness: 31%, $hue: 16deg, $saturation: -20%),
    adjust-color($primary, $lightness: -5%, $hue: 5deg, $saturation: -65%),
    adjust-color($primary, $lightness: -7%, $hue: 5deg, $saturation: -65%),
    adjust-color($primary, $lightness: -6%, $hue: 5deg, $saturation: -65%)
  );
}

@mixin background-gradient-silver-ring($primary: #150d40) {
  @include background-gradient(
    $primary,
    #7c8889,
    #0d0d0d,
    #b2c2c2,
    #b3c2c7,
    #828282,
    #7d7f7f
  );
}

@mixin white-child-svg() {
  img {
    filter: invert(1) drop-shadow(0 0 2px #000);
  }
}
