.happiness-report {
  --i18n-breakdown: attr(data-i18n-breakdown string, 'Breakdown');

  .city {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;

    &.celebrateLeader .name {
      font-weight: bold;
      text-shadow: 0 0 5px #0ce0e8;
    }

    &.civilDisorder .name {
      color: #7e2727;
      font-weight: bold;
    }

    .name {
      margin-right: 0.5rem;
    }

    .reasons {
      display: flex;
      flex-direction: column;
      grid-area: span 2;
      grid-column: 1 / 3;
      margin-bottom: 1rem;

      &::before {
        content: var(--i18n-breakdown);
        display: block;
        font-weight: bold;
        margin: 0.5rem;
      }

      &.hidden {
        display: none;
      }

      .reason {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-left: 1rem;

        .population {
          margin-right: 1rem;
        }
      }
    }
  }
}
