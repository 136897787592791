.city-screen-window {
  display: flex;
  flex-direction: column;

  .body {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.city-screen {
  display: flex;
  flex: 1;
  flex-direction: column;

  .city-map {
    min-width: 160px;
    text-align: center;
    width: 20%;
  }

  .bottom-row {
    align-items: stretch;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    flex: 1 1;
  }

  .top-row,
  .bottom-row {
    display: flex;

    .yield-details,
    .improvements,
    .growth,
    .tabbed-details,
    .build {
      flex: 1 1;
      padding: 0.25rem;
    }

    .build {
      text-align: center;

      header {
        min-height: 2.25rem;
      }
    }

    .yield-details,
    .improvements {
      max-width: 40%;
    }
  }

  .yield-details .yield,
  .population {
    display: flex;

    .citizen {
      // TODO: this shouldn't be hard-coded and should instead be provided by the asset-pack
      max-width: 12px;
      min-width: 0;

      img {
        // TODO: this shouldn't be hard-coded and should instead be provided by the asset-pack
        width: 16px;
        image-rendering: pixelated;
      }
    }
  }

  .yield-icon {
    min-width: 0;
    padding-right: 2px;

    img {
      // TODO: this shouldn't be hard-coded and should instead be provided by the asset-pack
      width: 14px;
      image-rendering: pixelated;
    }
  }

  .yield-details .yield {
    display: flex;

    .used {
      flex: 0 1;

      img {
        filter: sepia(1) grayscale(1) brightness(0.5);
      }
    }

    .free {
      flex: 1 1;
    }

    .used,
    .free {
      display: flex;
    }

    &:not(:last-child),
    .used:not(:empty) {
      margin-right: 0.5em;
    }

    @for $i from 0 through 14 {
      &[data-max-width='#{$i}'] {
        .yield-icon {
          max-width: #{$i}px;
        }
      }
    }
  }

  .tabbed-details,
  .city-map {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-width: 0 1px;
  }

  .improvements .yield-icon:first-child {
    margin-left: 0.5rem;
  }
}
