:root {
  --scale: 2;
}

body {
  // TODO: look into why this doesn't work like I'd expect!
  //--scale: attr(data-scale number, 2);
  background: #000;
  color: #edc;
  display: grid;
  font-family: sans-serif;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  user-select: none;
}

input {
  user-select: all;
}

#preload {
  height: 0;
  position: absolute;
  width: 0;
  overflow: hidden;
}

@import 'buttons';
@import 'components/actions';
@import 'components/city';
@import 'components/city-status';
@import 'components/game';
@import 'components/game-menu';
@import 'components/game-options';
@import 'components/happiness-report';
@import 'components/icons';
@import 'components/mainmenu';
@import 'components/map';
@import 'components/notification-area';
@import 'components/notification-window';
@import 'components/popup-menu';
@import 'components/releases';
@import 'components/science-report';
@import 'components/selection-window';
@import 'components/sidebar';
@import 'components/spaceship-window';
@import 'components/supported-unit';
@import 'components/trade-report';
@import 'components/window';
