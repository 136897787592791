.popup-menu {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 1;
  padding: 0.5rem;
  position: absolute;
  transition: all 0.2s;

  &.center {
    transform: translate(-50%, -1rem);
  }

  &.right {
    transform: translate(-100%, 0);
  }

  &.hide {
    opacity: 0;
  }

  button {
    background: rgba(255, 255, 255, 0.85);
    border: 0;
    border-radius: 0.66rem;
    color: #000;
    cursor: pointer;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
    }

    &:last-child {
      margin: 0;
    }
  }

  &.full-width {
    white-space: nowrap;
  }
}
