#map {
  display: flex;
  flex-grow: 1;
  max-height: 100vh;

  canvas {
    height: 100%;
    width: 100%;
  }
}
